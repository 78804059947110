import store from "@/store";

export default {
    install(Vue) {
        Vue.prototype.can = function (value) {
            let permissions = store.state.permissions;
            let _return = false;
            if (!Array.isArray(permissions)) {
                return false;
            }
            if (value.includes('|')) {
                value.split('|').forEach(function (item) {
                    if (permissions.includes(item.trim())) {
                        _return = true;
                    }
                });
            } else if (value.includes('&')) {
                _return = true;
                value.split('&').forEach(function (item) {
                    if (!permissions.includes(item.trim())) {
                        _return = false;
                    }
                });
            } else {
                _return = permissions.includes(value.trim());
            }
            return _return;
        }
    }
}