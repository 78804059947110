<template>
  <div>

    <!--**
    **Desktop**
    **-->
    <v-navigation-drawer :class="$vuetify.theme.dark ? 'dark-sidebar' : 'light-sidebar'"
                         :floating="!$vuetify.theme.dark" :mini-variant="miniVariant"
                         app
                         dark
                         mini-variant-width="70">

      <vue-scroll :ops="ops">
        <div @mouseleave="mouseLeave" @mouseover="mouseOver">
          <Sidebar :mini-variant="miniVariant"/>
        </div>
      </vue-scroll>
    </v-navigation-drawer>


    <Navbar @miniVariant="miniVariant = !miniVariant" @mobileDrawer="mobileDrawer = !mobileDrawer"/>

    <!--**
    **Mobile**
    **-->
    <v-navigation-drawer v-model="mobileDrawer"
                         :class="$vuetify.theme.dark ? 'dark-sidebar' : 'light-sidebar'"
                         :floating="!$vuetify.theme.dark"
                         app
                         dark>

      <vue-scroll :ops="ops">
        <div @mouseleave="mouseLeave" @mouseover="mouseOver">
          <Sidebar :mini-variant="false"/>
        </div>
      </vue-scroll>

    </v-navigation-drawer>


  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar";
import Navbar from "@/components/Navbar";

export default {
  components: {
    Sidebar,
    Navbar
  },
  data() {
    return {
      ops: {
        bar: {
          opacity: 0.6,
          background: "#fff",
          size: '6px',
          keepShow: false,

        },
        scrollPanel: {
          scrollingX: false,
        }
      },
      mobileDrawer: false,
      miniVariant: false
    }
  },
  methods: {
    mouseOver() {
      this.ops.bar.keepShow = true
    },
    mouseLeave() {
      this.ops.bar.keepShow = false
    }
  }
}
</script>

<style>
.light-sidebar .v-navigation-drawer__content {
  background-size: cover;
  background-position: center;
  background-image: linear-gradient(238deg, rgba(4, 4, 4, .05), rgba(4, 4, 4, .05) 56%, hsla(0, 0%, 82.7%, .05) 0, hsla(0, 0%, 82.7%, .05)), linear-gradient(223deg, rgba(96, 96, 96, .05), rgba(96, 96, 96, .05) 58%, hsla(0, 0%, 63.1%, .05) 0, hsla(0, 0%, 63.1%, .05)), linear-gradient(263deg, hsla(0, 0%, 91%, .05), hsla(0, 0%, 91%, .05) 65%, hsla(0, 0%, 95.3%, .05) 0, hsla(0, 0%, 95.3%, .05)), linear-gradient(90deg, #FF7D14, #FF7D30);
}

.dark-sidebar .v-navigation-drawer__content {
  background-size: cover;
  background: #312d4b center;
}

.btn-add::before, .btn-add:hover::before, .btn-add:focus::before {
  opacity: .10 !important;
}

.light-sidebar .v-navigation-drawer__append {
  background: #FF7D14;
}

.dark-sidebar .v-navigation-drawer__append {
  background: #312d4b;
}

</style>