<template>
  <div id="app">
    <v-app>
      <Navigation v-if="isLoggedIn"/>
      <v-main>
        <ConfirmDialog/>
        <ErrorMessage/>
        <SuccessMessage/>
        <vue-progress-bar></vue-progress-bar>

        <router-view/>

        <v-btn v-show="fab"
               v-scroll="onScroll"
               bottom
               color="primary"
               dark
               fab
               fixed
               right
               @click="toTop">
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>

      </v-main>
      <Footer v-if="isLoggedIn"/>
    </v-app>
  </div>
</template>
<script>
import Navigation from "@/components/Navigation";
import ErrorMessage from "@/components/Notifications/ErrorMessage";
import SuccessMessage from "@/components/Notifications/SuccessMessage";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import Footer from "@/components/Footer";
import {HTTP} from "@/http-common";

export default {
    components: {Footer, SuccessMessage, ErrorMessage, Navigation, ConfirmDialog},
    data() {
        return {
            fab: false,
            isLoggedIn: this.$store.state.isLoggedIn,
        }
    },
    methods: {
        onScroll(e) {
            if (typeof window === 'undefined') return
            const top = window.pageYOffset || e.target.scrollTop || 0
            this.fab = top > 20
        },
        toTop() {
            this.$vuetify.goTo(0)
        },

        getBase() {
            HTTP.get('base').then(res => {
                let campaigns = res.data.data.campaigns
                let users = res.data.data.users
                let raisons = res.data.data.raisons
                let cities = res.data.data.cities
                let all_cities = res.data.data.all_cities
                let keyword = res.data.data.keyword
                let salepoint_filter_elements = res.data.data.salepoint_filter_elements
                let task_models = res.data.data.task_models
                let brands = res.data.data.brands
                let categories = res.data.data.categories
                let permissions = res.data.data.permissions.roles.length > 0 ? res.data.data.permissions.roles[0].permissions.map(el => el.name) : []
                let products = res.data.data.products.map(v => {
                    return {
                        id: v.id,
                        name: v.name,
                        code: v.code,
                        brand_id: v.brand_id,
                        category_id: v.category_id,
                        photo: v.photo,
                        is_active: !!v.is_active,
                    }
                })

                this.$store.dispatch('campaigns', campaigns)
                this.$store.dispatch('users', users)
                this.$store.dispatch('raisons', raisons)
                this.$store.dispatch('cities', cities)
                this.$store.dispatch('all_cities', all_cities)
                this.$store.dispatch('keyword', keyword)
                this.$store.dispatch('salepoint_filter_elements', salepoint_filter_elements)
                this.$store.dispatch('task_models', task_models)
                this.$store.dispatch('brands', brands)
                this.$store.dispatch('categories', categories)
                this.$store.dispatch('permissions', permissions)
                this.$store.dispatch('products', products)

            }).catch(err => {
                console.log(err)
            })
        },
    },
    created() {
        if (this.isLoggedIn) {
            this.getBase()
        }
        //Disable back in browser
        // history.pushState(null, null, location.href);
        // window.onpopstate = function () {
        //     history.go(1);
        // };
        console.log(
            "%cWelcome to TIP",
            "color:#FF7D14;font-family:system-ui;font-size:3rem;-webkit-text-stroke: 1px black;font-weight:bold"
        );
    },
}
</script>

