import Vue from "vue";

let currantData = new Vue({
    data: {
        $Nprogress: false,
        $confirm_dialog: false,
        $is_confirm: false,
        $confirm_dialog_title: 'CONFIRMATION',
        $confirm_dialog_body: 'Êtes-vous sûr de vouloir SUPPRIMER cet élément',
        $btn_confirm_dialog: 'OK',
        $btn_cancel_dialog: 'Annuler',
        $successMessage: '',
        $errorMessage: '',
    },
});
Vue.mixin({
    computed: {
        $Nprogress: {
            get: function () {
                return currantData.$data.$Nprogress
            },
            set: function (boolean) {
                currantData.$data.$Nprogress = boolean;
            }
        },
        $confirm_dialog: {
            get: function () {
                return currantData.$data.$confirm_dialog
            },
            set: function (boolean) {
                currantData.$data.$confirm_dialog = boolean;
            }
        },
        $is_confirm: {
            get: function () {
                return currantData.$data.$is_confirm
            },
            set: function (boolean) {
                currantData.$data.$is_confirm = boolean;
            }
        },
        $confirm_dialog_title: {
            get: function () {
                return currantData.$data.$confirm_dialog_title
            },
            set: function (data) {
                currantData.$data.$confirm_dialog_title = data;
            }
        },
        $confirm_dialog_body: {
            get: function () {
                return currantData.$data.$confirm_dialog_body
            },
            set: function (data) {
                currantData.$data.$confirm_dialog_body = data;
            }
        },
        $btn_confirm_dialog: {
            get: function () {
                return currantData.$data.$btn_confirm_dialog
            },
            set: function (data) {
                currantData.$data.$btn_confirm_dialog = data;
            }
        },
        $btn_cancel_dialog: {
            get: function () {
                return currantData.$data.$btn_cancel_dialog
            },
            set: function (data) {
                currantData.$data.$btn_cancel_dialog = data;
            }
        },
        $successMessage: {
            get: function () {
                return currantData.$data.$successMessage
            },
            set: function (data) {
                currantData.$data.$successMessage = data;
            }
        },
        $errorMessage: {
            get: function () {
                return currantData.$data.$errorMessage
            },
            set: function (data) {
                currantData.$data.$errorMessage = data;
            }
        },
    }
});