<template>
  <div>
    <v-dialog v-model="dialog" max-width="400" persistent>
      <v-card class="text-center">
        <v-card-text class="pa-6">

          <v-icon color="primary" size="110">mdi-information-outline</v-icon>

          <h2 class="text--primary mt-3">{{ $confirm_dialog_title }}</h2>

          <p class="mt-5 mb-5"> {{ $confirm_dialog_body }}</p>

          <v-btn text class="mr-2"
                 @click="$confirm_dialog = false">
            <v-icon left>mdi-close</v-icon>
            {{ $btn_cancel_dialog }}
          </v-btn>

          <v-btn  color="primary"
                  depressed
                 @click="[$is_confirm = true,$confirm_dialog = false]">
            <v-icon left>mdi-check</v-icon>
            {{ $btn_confirm_dialog }}
          </v-btn>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false
    }
  },
  watch: {
    '$confirm_dialog': function (el) {
      this.dialog = !!el;
    }
  },
}
</script>

<style scoped>

</style>