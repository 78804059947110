export const campaignState = {
    id: '',
    name: '',
    name_ar: '',
    description: '',
    description_ar: '',
    photo: '',
    dates: [],
    tasks: [
        {
            name: '',
            name_ar: '',
            description: '',
            description_ar: '',
            winning_points: '',
            photo: '',

            upload_loading: false,
            upload_errors: [],

            dates: [],
            dateMenu: false,
            salepoint_ids: [],
            task_model_id: 1,
            question_type_key: '',
            questions: [
                {
                    question_type_key: 'image',
                    question_type_id: 1,
                    answer_type_id: 1,
                    content: '',
                    upload_loading: false,
                    is_video: false,
                    is_none: true,
                    description: '',
                    description_ar: '',
                    conditions: [],
                    options: [],
                }
            ],
        }
    ],
}