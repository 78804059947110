import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import {campaignState} from './campaignState'

Vue.use(Vuex)

const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        salepoint_filter_elements: [],
        wholesaler_filter_elements: [],
        cities: [], //user affected cities
        all_cities: [],
        permissions: [],
        raisons: [],
        users: [],
        campaigns: [],
        asm_campaigns: [],
        products: [],
        categories: [],
        brands: [],
        task_models: [],
        user: '',
        keyword: {},
        isLoggedIn: false,
        campaign: campaignState,
        stepperValidation: {
            stepper1: false,
            stepper2: false,
        },
    },
    mutations: {
        resetStore: (state, initialState) => {
            store.state.campaign = initialState
        },
        salepoint_filter_elements: (state, data) => {
            state.salepoint_filter_elements = data
        },
        wholesaler_filter_elements: (state, data) => {
            state.wholesaler_filter_elements = data
        },
        cities: (state, data) => {
            state.cities = data
        },
        all_cities: (state, data) => {
            state.all_cities = data
        },
        permissions: (state, data) => {
            state.permissions = data
        },
        raisons: (state, data) => {
            state.raisons = data
        },
        users: (state, data) => {
            state.users = data
        },
        campaigns: (state, data) => {
            state.campaigns = data
        },
        asm_campaigns: (state, data) => {
            state.asm_campaigns = data
        },
        products: (state, data) => {
            state.products = data
        },
        categories: (state, data) => {
            state.categories = data
        },
        brands: (state, data) => {
            state.brands = data
        },
        task_models: (state, data) => {
            state.task_models = data
        },
        keyword: (state, data) => {
            state.keyword = data
        },
        user: (state, data) => {
            state.user = data
        },
        isLoggedIn: (state, boolean) => {
            state.isLoggedIn = boolean
        },


        addTask: (state, data) => {
            state.campaign.tasks.push(data)
        },
        duplicateTask: (state, data) => {
            state.campaign.tasks.push(JSON.parse(JSON.stringify(data)))
        },
        removeTask: (state, i) => {
            state.campaign.tasks.splice(i, 1)
        },

        removeCondition: (state, data) => {
            state.campaign.tasks[data.taskIndex].questions[0].conditions.splice(data.conditionIndex, 1)
        },

        incrementCondition: (state, data) => {
            state.campaign.tasks[data.taskIndex].questions[0].conditions[data.conditionIndex].quantity += 1
        },
        decrementCondition: (state, data) => {
            if (state.campaign.tasks[data.taskIndex].questions[0].conditions[data.conditionIndex].quantity > 1)
                state.campaign.tasks[data.taskIndex].questions[0].conditions[data.conditionIndex].quantity -= 1
        },

        addQuestion: (state, data) => {
            state.campaign.tasks[data.taskIndex].questions.push(data.data)
        },
        removeQuestion: (state, data) => {
            state.campaign.tasks[data.taskIndex].questions.splice(data.questionIndex, 1)
        },
        duplicateQuestion: (state, data) => {
            state.campaign.tasks[data.taskIndex].questions.push(JSON.parse(JSON.stringify(data.data)))
        },


        addOption: (state, data) => {
            state.campaign.tasks[data.taskIndex].questions[data.questionIndex].options.push({
                option: '',
                option_ar: ''
            })
        },
        removeOption: (state, data) => {
            state.campaign.tasks[data.taskIndex].questions[data.questionIndex].options.splice(data.optionIndex, 1)
        },


        //validations
        stepper1: (state, boolean) => {
            state.stepperValidation.stepper1 = boolean
        },
        stepper2: (state, boolean) => {
            state.stepperValidation.stepper2 = boolean
        },

        //change user
        updateUser: (state, data) => {
            state.user.first_name = data.first_name;
            state.user.last_name = data.last_name;
            state.user.email = data.email;
        },
        updateUserPicture: (state, data) => {
            state.user.photo = data;
        },
    },
    actions: {
        salepoint_filter_elements({commit}, data) {
            commit("salepoint_filter_elements", data);
        },
        wholesaler_filter_elements({commit}, data) {
            commit("wholesaler_filter_elements", data);
        },
        permissions({commit}, data) {
            commit("permissions", data);
        },
        raisons({commit}, data) {
            commit("raisons", data);
        },
        users({commit}, data) {
            commit("users", data);
        },
        campaigns({commit}, data) {
            commit("campaigns", data);
        },
        asm_campaigns({commit}, data) {
            commit("asm_campaigns", data);
        },
        cities({commit}, data) {
            commit("cities", data);
        },
        all_cities({commit}, data) {
            commit("all_cities", data);
        },
        products({commit}, data) {
            commit("products", data);
        },
        categories({commit}, data) {
            commit("categories", data);
        },
        brands({commit}, data) {
            commit("brands", data);
        },
        task_models({commit}, data) {
            commit("task_models", data);
        },
        user({commit}, data) {
            commit("user", data);
        },
        keyword({commit}, data) {
            commit("keyword", data);
        },
        isLoggedIn({commit}, boolean) {
            commit("isLoggedIn", boolean);
        },
    },
})
export default store
