<template>
  <div>
    <v-snackbar :value="$errorMessage"
                color="red"
                bottom right timeout="5000">
      <v-icon left color="white">mdi-alert-circle-outline</v-icon>
      <span v-html="$errorMessage"></span>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  watch: {
    '$errorMessage': function (el) {
      if (el) {
        let _vm = this
        setTimeout(function () {
          _vm.$errorMessage = null
        }, 5000)
      }
    }
  },
}
</script>

<style scoped>

</style>