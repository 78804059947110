import Vue from 'vue'
import Vuetify from 'vuetify'
import fr from 'vuetify/es5/locale/fr';
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

import '../style/main.css'

Vue.use(Vuetify)
const opts = {
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#ff7d14',
                blue: '#3DB2FF',
                error: '#FF2442',
                success: '#79D70F',
                indigo: '#813EF9',
                grey: '#a7a6a6',
            },
            dark: {
                primary: '#FF7D14',
                blue: '#3DB2FF',
                error: '#FF2442',
                success: '#79D70F',
                indigo: '#813EF9',
                grey: '#28243d',
            },
        },
    },
    lang: {
        locales: {fr},
        current: 'fr',
    },
    icons: {
        iconfont: 'mdi',
    },
}
export default new Vuetify(opts)
