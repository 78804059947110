import store from "./store";

export const func = {
    resetStore: () => {
        let campaignState = {
            id: '',
            name: '',
            name_ar: '',
            description: '',
            description_ar: '',
            photo: '',
            dates: [],
            tasks: [
                {
                    name: '',
                    name_ar: '',
                    description: '',
                    description_ar: '',
                    winning_points: '',
                    photo: '',

                    upload_loading: false,
                    upload_errors: [],

                    dates: [],
                    dateMenu: false,
                    salepoint_ids: [],
                    task_model_id: 1,
                    question_type_key: '',
                    questions: [
                        {
                            question_type_key: 'image',
                            question_type_id: 1,
                            answer_type_id: 1,
                            content: '',
                            upload_loading: false,
                            is_video: false,
                            is_none: true,
                            description: '',
                            description_ar: '',
                            conditions: [],
                            options: [],
                        }
                    ],
                }
            ],
        }
        store.commit('resetStore', campaignState)
        store.commit('stepper1', false)
        store.commit('stepper2', false)
    },

    progressColor: (el) => {
        if (el <= 30) {
            return 'red'
        }
        if (el > 30 && el <= 60) {
            return 'yellow'
        }
        if (el > 60 && el <= 90) {
            return 'primary'
        }
        if (el > 90 && el <= 100) {
            return 'success'
        }
    },
}