<template>
  <div>
    <v-app-bar app :color="$vuetify.theme.dark ? '' : 'white'" class="shadow">

      <v-app-bar-nav-icon @click="$vuetify.breakpoint.mobile ? $emit('mobileDrawer') : $emit('miniVariant')"></v-app-bar-nav-icon>

      <v-spacer/>
      <v-btn v-if="isFullscreen" icon @click="minimize">
        <MinimizeIcon/>
      </v-btn>
      <v-btn v-else icon @click="maximize">
        <MaximizeIcon/>
      </v-btn>
      <v-btn icon @click="changeTheme">
        <SunIcon v-if="$vuetify.theme.dark"/>
        <MoonIcon v-else/>
      </v-btn>
      <v-badge class="mr-2" overlap content="0" offset-x="22" offset-y="22">
        <v-btn icon>
          <BellIcon/>
        </v-btn>
      </v-badge>
      <v-menu offset-y transition="slide-y-transition" bottom min-width="200">
        <template v-slot:activator="{ on, attrs }">
          <v-avatar v-bind="attrs" v-on="on" size="40">
            <v-img v-if="!$store.state.user.photo" :src="require('@/assets/avatar.png')"></v-img>
            <v-img v-else :src="FILE_URL+$store.state.user.photo"></v-img>
          </v-avatar>
        </template>


        <v-card>
          <v-card-text>

            <v-row align="center">
              <v-col cols="3">
                <v-avatar size="50">
                  <v-img v-if="!$store.state.user.photo" :src="require('@/assets/avatar.png')"></v-img>
                  <v-img v-else :src="FILE_URL+$store.state.user.photo"></v-img>
                </v-avatar>
              </v-col>
              <v-col cols="9">
                <strong class="text--primary">
                  {{ $store.state.user.first_name }}
                  {{ $store.state.user.last_name }}
                </strong>
                <span class="d-block">{{ $store.state.user.roles[0].name }}</span>
                <span class="d-block d-flex">
                  <v-icon small class="mr-1">mdi-email-outline</v-icon> {{ $store.state.user.email }}
                </span>
              </v-col>
            </v-row>
            <v-list nav>

              <v-list-item to="/account" two-line class="translateY">
                <v-list-item-avatar color="blue" size="35">
                  <v-icon color="white" size="20">mdi-account-cog</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>
                  Mon compte
                  <v-list-item-subtitle>Mot de passe,Email...</v-list-item-subtitle>
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="logout"
                           class="blue translateY">
                <v-list-item-avatar color="blue" size="35">
                  <v-icon color="white">mdi-power</v-icon>
                </v-list-item-avatar>
                <v-list-item-title class="white--text">
                  Déconnexion
                </v-list-item-title>
              </v-list-item>

            </v-list>
          </v-card-text>
        </v-card>

      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import {MaximizeIcon, MinimizeIcon, SunIcon, BellIcon, MoonIcon} from 'vue-feather-icons'

export default {
  components: {
    MaximizeIcon,
    MinimizeIcon,
    SunIcon,
    MoonIcon,
    BellIcon,
  },
  data() {
    return {
      loading: false,
      isFullscreen: false,
      FILE_URL: process.env.VUE_APP_FILE_URL
    }
  },
  methods: {
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    },
    logout() {
      this.$Progress.start()
      HTTP.post('logout').then(() => {
        this.$Progress.finish()
        this.$store.dispatch('user', '')
        this.$store.dispatch('isLoggedIn', false)
        this.$store.dispatch('permissions', [])
        this.$router.go(0)
      }).catch(err => {
        this.$Progress.fail()
        console.log(err)
      })
    },
    maximize() {
      let el = document.documentElement;
      if (el.requestFullscreen) {
        el.requestFullscreen();
      } else if (el.webkitRequestFullscreen) { /* Safari */
        el.webkitRequestFullscreen();
      } else if (el.msRequestFullscreen) { /* IE11 */
        el.msRequestFullscreen();
      }
      this.isFullscreen = true
    },
    minimize() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
      }
      this.isFullscreen = false
    },
  }
}
</script>

<style scoped>
</style>