<template>
  <div>
    <v-footer ref="footer" class="transparent mt-5">
      <v-col class="text-right" cols="12">
        COPYRIGHT © {{ (new Date().getFullYear()) }} <a class="text-decoration-none" href="#" target="_blank">Store Execution</a>,
        Tous droits réservés
      </v-col>
    </v-footer>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>